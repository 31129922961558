

<template>
    <div class="dio">
        <h1 class="titolografico">La nostra Visione </h1>
<div class="infocontent">
<div class="gallery">
    <img src="../assets/GeneratoreRoulette.jpg" alt="a dream catcher">
    <img src="../assets/slidestat.jpg" alt="a piano">
    <img src="../assets/slidesport.jpg" alt="a live concert">
    <img src="../assets/slidecompatibile.png" alt="Paris">
</div>

<div class="divissimo"> <h1 class="testissimo"><b>MONITORA, CONTROLLA, INVESTI.</b></h1>
<p class="paragrafissimo">La nostra visione è chiara: fornire ai nostri utenti gli strumenti indispensabili per gestire il <b>proprio budget </b>nel modo più <b>profittevole e responsabile sul lungo termine</b>. Sappiamo che il vero successo nelle scommesse non è questione di fortuna, ma di metodo, analisi e gestione consapevole</p> </div>      
</div>
</div>
    </template>
 
    <script>
    export default {
        data(){
            return {
                show_more : false
            }
        },
       
    }
    </script>
    
    <style>
    @media only screen and (min-width: 900px) {
    .titolografico{
        color: #252525;
    }
    .dio{
        margin-bottom: 2rem;
        margin-top: 2rem;
    }
    .divissimo{
        margin-left: 20px ;
        width: 40%;
    }
    .testissimo{
        font-family: 'Inter';
        font-style: italic;
        text-align: right;
        font-size: 60px;
        background: #0BA5EF;
background: linear-gradient(to right, #0BA5EF 0%, #6563f2 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
    .paragrafissimo{
        text-align: right;
        font-family: 'Inter';
        font-style: normal;
        font-size: 20px;
    }
    .infocontent{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .gallery  {
  --s: 350px; /* control the size */
  
  display: grid;
  align-items: center;
  justify-content: center;
  width: var(--s);
  aspect-ratio: 1;
  overflow: hidden;
  padding: calc(var(--s)/20);
  border-radius: 50%;
  position: relative;
  clip-path: circle(49.5%); /* to avoid a few glitch cause by overflow: hidden */
}
.gallery::after {
  content: "";
  position: absolute;
  inset: 0;
  padding: inherit;
  border-radius: inherit;

  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
}
.gallery > img {
  grid-area: 1/1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
  transform-origin: 50% 120.7%;
}
.gallery::after,
.gallery > img {
  animation: m 20s infinite cubic-bezier(.5,-0.2,.5,1.5);
}
.gallery > img:nth-child(2) {animation-delay: -5s}
.gallery > img:nth-child(3) {animation-delay: -10s}
.gallery > img:nth-child(4) {animation-delay: -15s}

@keyframes m {
  0%,1%    {transform: rotate(0)}
  22%,23%  {transform: rotate(-90deg)}
  47%,48%  {transform: rotate(-180deg)}
  72%,73%  {transform: rotate(-270deg)}
  98%,100% {transform: rotate(-360deg)}
}
}
@media only screen and (max-width: 899px) {
    .titolografico{
        color: #252525;
    }
    .dio{
        margin-bottom: 2rem;
        margin-top: 2rem;
    }
    .divissimo{
        margin-left: 20px ;
        width: 80%;
    }
    .testissimo{
        font-family: 'Inter';
        font-style: italic;
        text-align: center;
        font-size: 60px;
        background: #0BA5EF;
background: linear-gradient(to right, #0BA5EF 0%, #6563f2 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
    .paragrafissimo{
        
        text-align: center;
        font-family: 'Inter';
        font-style: normal;
        font-size: 20px;
    }
    .infocontent{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .gallery  {
  --s: 350px; /* control the size */
  
  display: grid;
  align-items: center;
  justify-content: center;
  width: var(--s);
  aspect-ratio: 1;
  overflow: hidden;
  padding: calc(var(--s)/20);
  border-radius: 50%;
  position: relative;
  clip-path: circle(49.5%); /* to avoid a few glitch cause by overflow: hidden */
}
.gallery::after {
  content: "";
  position: absolute;
  inset: 0;
  padding: inherit;
  border-radius: inherit;

  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
}
.gallery > img {
  grid-area: 1/1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
  transform-origin: 50% 120.7%;
}
.gallery::after,
.gallery > img {
  animation: m 20s infinite cubic-bezier(.5,-0.2,.5,1.5);
}
.gallery > img:nth-child(2) {animation-delay: -5s}
.gallery > img:nth-child(3) {animation-delay: -10s}
.gallery > img:nth-child(4) {animation-delay: -15s}

@keyframes m {
  0%,1%    {transform: rotate(0)}
  22%,23%  {transform: rotate(-90deg)}
  47%,48%  {transform: rotate(-180deg)}
  72%,73%  {transform: rotate(-270deg)}
  98%,100% {transform: rotate(-360deg)}
}
}
@media only screen and (max-width: 497px) {
    .titolografico{
        color: #252525;
    }
    .dio{
        margin-bottom: 2rem;
        margin-top: 2rem;
    }
    .divissimo{
        width: 80%;
    }
    .testissimo{
        font-family: 'Inter';
        font-style: italic;
        text-align: center;
        font-size: 48px;
        background: #0BA5EF;
background: linear-gradient(to right, #0BA5EF 0%, #6563f2 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
    .paragrafissimo{
        
        text-align: center;
        font-family: 'Inter';
        font-style: normal;
        font-size: 18px;
    }
    .infocontent{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .gallery  {
  --s: 350px; /* control the size */
  
  display: grid;
  align-items: center;
  justify-content: center;
  width: var(--s);
  aspect-ratio: 1;
  overflow: hidden;
  padding: calc(var(--s)/20);
  border-radius: 50%;
  position: relative;
  clip-path: circle(49.5%); /* to avoid a few glitch cause by overflow: hidden */
}
.gallery::after {
  content: "";
  position: absolute;
  inset: 0;
  padding: inherit;
  border-radius: inherit;

  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
}
.gallery > img {
  grid-area: 1/1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
  transform-origin: 50% 120.7%;
}
.gallery::after,
.gallery > img {
  animation: m 20s infinite cubic-bezier(.5,-0.2,.5,1.5);
}
.gallery > img:nth-child(2) {animation-delay: -5s}
.gallery > img:nth-child(3) {animation-delay: -10s}
.gallery > img:nth-child(4) {animation-delay: -15s}

@keyframes m {
  0%,1%    {transform: rotate(0)}
  22%,23%  {transform: rotate(-90deg)}
  47%,48%  {transform: rotate(-180deg)}
  72%,73%  {transform: rotate(-270deg)}
  98%,100% {transform: rotate(-360deg)}
}
}
@media only screen and (max-width: 400px) {
    .titolografico{
        color: #252525;
    }
    .dio{
        margin-bottom: 2rem;
        margin-top: 2rem;
    }
    .divissimo{
        width: 80%;
    }
    .testissimo{
        font-family: 'Inter';
        font-style: italic;
        text-align: center;
        font-size: 36px;
        background: #0BA5EF;
background: linear-gradient(to right, #0BA5EF 0%, #6563f2 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
    .paragrafissimo{
        
        text-align: center;
        font-family: 'Inter';
        font-style: normal;
        font-size: 18px;
    }
    .infocontent{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .gallery  {
  --s: 350px; /* control the size */
  
  display: grid;
  align-items: center;
  justify-content: center;
  width: var(--s);
  aspect-ratio: 1;
  overflow: hidden;
  padding: calc(var(--s)/20);
  border-radius: 50%;
  position: relative;
  clip-path: circle(49.5%); /* to avoid a few glitch cause by overflow: hidden */
}
.gallery::after {
  content: "";
  position: absolute;
  inset: 0;
  padding: inherit;
  border-radius: inherit;

  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
}
.gallery > img {
  grid-area: 1/1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
  transform-origin: 50% 120.7%;
}
.gallery::after,
.gallery > img {
  animation: m 20s infinite cubic-bezier(.5,-0.2,.5,1.5);
}
.gallery > img:nth-child(2) {animation-delay: -5s}
.gallery > img:nth-child(3) {animation-delay: -10s}
.gallery > img:nth-child(4) {animation-delay: -15s}

@keyframes m {
  0%,1%    {transform: rotate(0)}
  22%,23%  {transform: rotate(-90deg)}
  47%,48%  {transform: rotate(-180deg)}
  72%,73%  {transform: rotate(-270deg)}
  98%,100% {transform: rotate(-360deg)}
}
}
    </style>