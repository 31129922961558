import '../assets/stats.css';

const Statistiche = () => {
  return (
    <div style=" height: auto; padding:25px; ">
      <h1 id="titolo2"><b>I NOSTRI RISULTATI </b></h1>
      <p id="paragrafo2" style="padding: 20px; text-align:left; color:#202020">Uno dei nostri strumenti più efficaci è il <b>Money Manager</b>, che ci permette di avere una visione chiara e trasparente dei nostri investimenti sul lungo periodo. I nostri risultati sono pubblici e consultabili direttamente attraverso il bottone qui sotto, che vi rimanderà al nostro Money Manager. 
</p><div style="display:flex;align-items:center;justify-content:center">
<button onclick="window.open('https://docs.google.com/spreadsheets/d/1qcKtAOaKUxOW84YBn6pqByBjhxiDS5tekJWg3NvkL8A/edit?usp=drive_link', '_blank')" style="background-color: rgba(18,162,233,1); color: white; padding: 10px 20px; border: none; border-radius: 5px; font-size: 16px; cursor: pointer;">I NOSTRI RISULTATI</button>
</div>
      
    </div>
  );
};


export default Statistiche;
