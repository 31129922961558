<template>
	<div class="w-screen  overflow-x-hidden" id="#app">
		<NavBar></NavBar>
        <Results></Results>
		<Footer></Footer>
	</div>
</template>

<script>
import NavBar from "../components/NavBar.vue";
import Results from "../components/risultati";
import Footer from "../components/Footer.vue";

export default {
	name: "Bonuses",
	components: {
		NavBar,
        Results,
		Footer
	},
	data() {
		return {
			items: [
				{
					header: "SOFTWARE ROULETTE",
					text1:
						"Il Software Roulette offre un interfaccia completa ed intuitiva per la gestione delle puntate e degli Stake al gioco della Roulette (compatibile con le Roulette Live Casinò disponibili nella lista dei book).",
					text2:
						"L’Algoritmo per la selezione delle Sequenze è definito su base Statistica, un’analisi di devianza di oltre 100000 numeri risultati sui vari Provider. Attraverso il Software Roulette l’Utente potrà inserire gli ultimi numeri risultati alla Roulette sui sta giocando ed avere la possibilità di ottenere i consigli generati automaticamente.",
					image:require('../assets/RouletteScreen.png'),
					position: 'RIGHT'

				},
				{
					header: "SOFTWARE SPORT",
					text1:
						"I segreti per vincere in questo settore, sono costanza e precisione, il tutto riassunto in unico posto!",
					text2:
						"I segreti per vincere in questo settore, sono costanza e precisione, il tutto riassunto in unico posto!L’algoritmo di generazione partite che regola il Software Sport è definito anch’esso su base Statistica, attraverso la valutazione di una serie di fattori, tra cui Statistiche generali e andamenti per squadra. Il principale vantaggio del Software Sport è che ogni pronostico viene autogenerato anche considerando gli andamenti del budget dell’utente.",
					image:require('../assets/SportScreen.png'),
					position: 'LEFT'
				},
				{
					header: "FOGLIO DI GESTIONE BUDGDET",
					text1:
						"La vera rivoluzione a livello di Gestione del Budget.",
					text2:
						`Uno dei principali problemi per uno Scommettiore è proprio l’incapacità di gestire le proprie Risorse, perdendo così la possibilità di valutare altre alternative di investimento. 
Attraverso questa Sezione, l’utente non solo potrà monitorare le giocate proposte dagli Algoritmi PLTNVM, ma avrà anche la possibilità di inserire anche le giocate ideate da lui mettendole a disposizione della Community!`,
					
				},
				{
					header: "COMMUNITY PREMIUM",
					text1:
						"Condividi i tuoi risultati con gli altri utenti Premium!",
					text2:
						`Un concetto a Noi particolarmente caro è la Community, motivo per cui ogni Utente PLTNVM (anche gratuito) potrà condividere i propri pronostici con i suoi Seguaci. Attraverso la Condivisione, è possibile instaurare rapporti con altri giocatori e crescere insieme.`,
					
				},
			],
		};
	},
};
</script>

<style>
</style>